<app-block-slideshow [withDepartments]="true"></app-block-slideshow>
<!--
    <app-block-features></app-block-features>
-->
<app-block-products-carousel
    *ngIf="StoreSvc.configuracionSitio.VerBLoqueValoradosEspecialesVendidos"
    header="Ofertas especiales"
    layout="horizontal"
    [rows]="2"
    [loading]="productsOferta?.loading"
    [products]="productsOferta?.products"
    [groups]="productsOferta?.groups"
    (groupChange)=" groupChangeOferta(productsOferta, $event)"
></app-block-products-carousel>

<app-block-products-carousel
    *ngIf="StoreSvc.configuracionSitio.VerProductosDestacados"
    header="Productos Destacados"
    layout="grid-4"
    [loading]="featuredProducts?.loading"
    [products]="featuredProducts?.products"
    [groups]="featuredProducts?.groups"
    (groupChange)="groupChangeDestacados(featuredProducts, $event)"
></app-block-products-carousel>

<app-block-banner *ngIf="StoreSvc.configuracionSitio.VerBannerIntermedio"></app-block-banner>

<app-block-products *ngIf="StoreSvc.configuracionSitio.VerMasVendidos"
header="Mas Vendidos" layout="large-first" [products]="bestsellers"></app-block-products>


<app-block-categories *ngIf="StoreSvc.configuracionSitio.VerCategoriasPopulares" header="Categorias más Populares" layout="classic" [categories]="popularCategories"></app-block-categories>

<app-block-products-carousel
    *ngIf="StoreSvc.configuracionSitio.VerRecienllegados"
    header="Recién llegados"
    layout="horizontal"
    [rows]="2"
    [loading]="latestProducts?.loading"
    [products]="latestProducts?.products"
    [groups]="latestProducts?.groups"
    (groupChange)="groupChangeRecienLlegdos(latestProducts, $event)"
></app-block-products-carousel>

<app-block-posts *ngIf="StoreSvc.configuracionSitio.VerUltimasNoticias" header="Últimas Noticias" layout="list-sm" [posts]="posts"></app-block-posts>

<app-block-brands *ngIf="StoreSvc.configuracionSitio.VerMarcas" [brands]="brands"></app-block-brands>

<app-block-product-columns *ngIf="StoreSvc.configuracionSitio.VerBLoqueValoradosEspecialesVendidos" >
    <app-block-product-columns-item header="Productos Mejor Valorados" [products]="columnTopRated"></app-block-product-columns-item>
    <app-block-product-columns-item header="Mas Vendidos" [products]="columnBestsellers"></app-block-product-columns-item>
</app-block-product-columns>
