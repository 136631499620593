import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { ProductAttribute } from '../../interfaces/product';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { QuickviewService } from '../../services/quickview.service';
import { RootService } from '../../services/root.service';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// modelos
import { Item } from '../../../../data/modelos/articulos/Items';
import { StoreService } from '../../services/store.service';
import { UtilsTexto } from '../../utils/UtilsTexto';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit, OnDestroy, OnChanges {
    private destroy$: Subject<void> = new Subject();

    @Input() product: Item;
    @Input() layout: 'grid-sm'|'grid-nl'|'grid-lg'|'list'|'horizontal'|null = null;

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    showingQuickview = false;
    productosFavoritos = [];
    esFavorito : boolean = false;
    featuredAttributes: ProductAttribute[] = [];
    quick
    islogged
    quantity : number = 1;

    constructor(
        private cd: ChangeDetectorRef,
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService,
        public compare: CompareService,
        public quickview: QuickviewService,
        public currency: CurrencyService,
        public storeSvc: StoreService,
        private utils: UtilsTexto,
        private toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.islogged = localStorage.getItem("isLogue");
        // tslint:disable-next-line: deprecation
        this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.markForCheck();

        });

        this.cargarFavoritos();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('product' in changes) {
            // this.featuredAttributes = !this.product ? [] : this.product.attributes?.filter(x => x.featured);
        }
    }

    addToCart(): void {
        
        const availableInventory = this.product.inventario - this.product.inventarioPedido;

        // Verificar si se debe comprobar el inventario basado en la configuración
        if (!this.storeSvc.configuracionSitio.SuperarInventario) {
            // Verificar si la cantidad deseada excede el inventario disponible
            if (this.quantity > availableInventory) {
                this.toastr.error(
                    `Producto "${this.utils.TitleCase(this.product.name)}" no tiene suficiente inventario. Disponible: ${availableInventory}`
                );
                return;
            }
        }

        if (this.addingToCart) {
            return;
        }

        this.addingToCart = true;
        // tslint:disable-next-line: deprecation
        // tslint:disable-next-line: deprecation
        this.cart.add(this.product, this.quantity).subscribe({
            complete: () => {
                this.addingToCart = false;
                this.cd.markForCheck();
            }
        });
    }

    addToWishlist() {
        this.esFavorito = true;
        if (!this.addingToWishlist && this.product) {

                this.wishlist.add(this.product).then((data: any)=>{
                if(data){
                    this.addingToWishlist = false
                }
                });


        }
    }

    addToCompare(): void {
        if (this.addingToCompare) {
            return;
        }

        this.addingToCompare = true;
        // tslint:disable-next-line: deprecation
        this.compare.add(this.product).subscribe({
            complete: () => {
                this.addingToCompare = false;
                this.cd.markForCheck();
            }
        });
    }

    showQuickview(): void {
        if (this.showingQuickview) {
            return;
        }

        this.showingQuickview = true;
        // tslint:disable-next-line: deprecation
         this.quickview.show(this.product).subscribe({
            complete: () => {
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }


    cargarFavoritos(){
     this.productosFavoritos= JSON.parse(localStorage.getItem("favoritos"))
     const product =  this.productosFavoritos?.findIndex(element =>  element.id ===  this.product.id)
     this.esFavorito = product != -1
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get maxCantidad(): (product: Item) => number {
        return (product: Item) => this.storeSvc.configuracionSitio.SuperarInventario ? Infinity : product?.inventario;
    }

}
