<div class="site-header">
    <app-notifications></app-notifications>
    <app-header-topbar></app-header-topbar>

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo">
            <a routerLink="/">
                <img [src]="logo" alt="Logo - Carro de Compras" style="max-width: 196px; max-height: 80px"  loading="lazy">
            </a>
        </div>
        <div class="site-header__search">
            <app-search location="header"></app-search>
        </div>
        <div class="site-header__phone">
            <div class="site-header__phone-title">Servicio al Cliente</div>
            <div class="site-header__phone-number">{{ store.configuracionSitio.phone }}</div>
        </div>
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav
            [departments]="layout === 'classic'"
            [logo]="layout === 'compact'"
            [search]="layout === 'compact'"
            stickyMode="pullToShow"
        ></app-header-nav>
    </div>
</div>
