<div class="mobile-header" [ngClass]="{'mobile-header--sticky': stickyMode}" #element>
    <div class="mobile-header__panel" #panelElement>
        <div class="container">
            <div class="mobile-header__body">
                <button class="mobile-header__menu-button" (click)="menu.open()">
                    <app-icon name="menu-18x14" size="18x14"></app-icon>
                </button>
                <a class="mobile-header__logo" routerLink="/">
                    <img [src]="logo" alt="Logo - Carro de Compras" style="max-width: 120px; max-height: 40px"  loading="lazy">
                </a>
                <app-search
                    appDropdown="mobile-header__search--open"
                    class="mobile-header__search"
                    location="mobile-header"
                    (closeButtonClick)="searchDropdown.close($event)"
                    #search="search"
                    #searchDropdown="appDropdown"
                ></app-search>

                <div class="mobile-header__indicators">
                    <div class="indicator indicator--mobile-search indicator--mobile d-md-none">
                        <button class="indicator__button" (click)="searchDropdown.open(); search.inputElement.focus()">
                            <span class="indicator__area">
                                <app-icon name="search-20" size="20"></app-icon>
                            </span>
                        </button>
                    </div>

                    <div class="indicator indicator--mobile ">
                        <a routerLink="./shop/wishlist" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="heart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ wishlist.count$|async }}</span>
                            </span>
                        </a>
                    </div>

                    <div class="indicator indicator--mobile">
                        <ng-container *ngIf="showPrice(); else showAlert">
                            <a routerLink="./shop/cart" class="indicator__button">
                                <span class="indicator__area">
                                    <app-icon name="cart-20" size="20"></app-icon>
                                    <span class="indicator__value">{{ cart.quantity$ | async }}</span>
                                </span>
                            </a>
                        </ng-container>
                        <ng-template #showAlert>
                            <button class="indicator__button" (touchstart)="showLoginAlert()">
                                <span class="indicator__area">
                                    <app-icon name="cart-20" size="20"></app-icon>
                                    <span class="indicator__value">{{ cart.quantity$ | async }}</span>
                                </span>
                            </button>
                        </ng-template>
                    </div>
                    

                <app-indicator link="./account" icon="person-20" trigger="click" #indicator="appIndicator">
                    <app-account-menu (closeMenu)="indicator.close(true)"></app-account-menu>
                </app-indicator>
                </div>
            </div>
        </div>
    </div>
</div>
