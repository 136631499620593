import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BlockHeaderGroup } from '../../../shared/interfaces/block-header-group';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Item } from '../../../../data/modelos/articulos/Items';

@Component({
    selector: 'app-block-products-carousel',
    templateUrl: './block-products-carousel.component.html',
    styleUrls: ['./block-products-carousel.component.scss']
})
export class BlockProductsCarouselComponent implements OnChanges, OnInit {

    @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
    startX: number = 0;

    @Input() header: string;
    @Input() layout: 'grid-4' | 'grid-4-sm' | 'grid-5' | 'horizontal' = 'grid-4';
    @Input() rows = 1;
    @Input() products: Item[] = [];
    @Input() groups: BlockHeaderGroup[] = [];
    @Input() withSidebar = false;
    @Input() loading = false;

    @Output() groupChange: EventEmitter<BlockHeaderGroup> = new EventEmitter();

    columns: Item[][] = [];

    carouselOptionsByLayout: any = {
        'grid-4': {
            responsive: {
                1110: { items: 4, margin: 14 },
                930: { items: 4, margin: 10 },
                690: { items: 3, margin: 10 },
                400: { items: 2, margin: 10 },
                0: { items: 1 }
            }
        },
        'grid-4-sm': {
            responsive: {
                820: { items: 4, margin: 14 },
                640: { items: 3, margin: 10 },
                400: { items: 2, margin: 10 },
                0: { items: 1 }
            }
        },
        'grid-5': {
            responsive: {
                1110: { items: 5, margin: 12 },
                930: { items: 4, margin: 10 },
                690: { items: 3, margin: 10 },
                400: { items: 2, margin: 10 },
                0: { items: 1 }
            }
        },
        horizontal: {
            responsive: {
                1110: { items: 3, margin: 14 },
                930: { items: 3, margin: 10 },
                690: { items: 2, margin: 10 },
                0: { items: 1 }
            }
        }
    };

    constructor(config: NgbCarouselConfig) {
        config.interval = 15000;
        config.wrap = true;
        config.showNavigationArrows = false;
        config.showNavigationIndicators = false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setCarouselOptions();
    }

    ngOnInit(): void {
        this.setCarouselOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const properties = Object.keys(changes);

        if (properties.includes('products') || properties.includes('rows')) {
            this.setCarouselOptions();
        }
    }

    setCarouselOptions() {
        const width = window.innerWidth;
        const layoutOptions = this.carouselOptionsByLayout[this.layout].responsive;
        let itemsToShow = 1;

        for (const breakpoint of Object.keys(layoutOptions).map(key => parseInt(key)).sort((a, b) => b - a)) {
            if (width >= breakpoint) {
                itemsToShow = layoutOptions[breakpoint].items;
                break;
            }
        }

        this.createColumns(this.rows, itemsToShow);
    }

    createColumns(rowsPerColumn: number, itemsPerColumn: number) {
        this.columns = [];
        const products = this.products?.slice();
        const totalItemsPerColumn = rowsPerColumn * itemsPerColumn;

        while (products && products.length > 0) {
            this.columns.push(products.splice(0, totalItemsPerColumn));
        }
    }


    handleTouchMove(event: TouchEvent) {
        // Get the touch points
        const touch = event.touches[0]; // Assuming single-touch for simplicity
        if (!this.startX) {
            // Record the initial touch position
            this.startX = touch.pageX;
            return;
        }

        // Calculate the distance moved
        const currentX = touch.pageX;
        const distance = currentX - this.startX;

        // Threshold for swipe detection
        const threshold = 50; // Adjust this value as needed

        // Determine the swipe direction
        if (Math.abs(distance) > threshold) {
            if (distance > 0) {
                // Swipe right
                this.carousel.prev(); // Go to the previous slide
            } else {
                // Swipe left
                this.carousel.next(); // Go to the next slide
            }

            // Reset startX to avoid continuous triggering
            this.startX = 0;
        }
    }

}
