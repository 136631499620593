<!-- .block-products-carousel -->
<div class="block block-products-carousel" [ngClass]="{
        'block-products-carousel--loading': loading,
        'block-products-carousel--has-items': !!columns?.length
    }" [attr.data-layout]="layout">
    <div [ngClass]="{'container': !withSidebar}">
        
        <app-block-header [header]="header" [groups]="groups" [arrows]="true" (next)="carousel.next()"
            (prev)="carousel.prev()" (groupChange)="groupChange.emit($event)"></app-block-header>

        <div class="block-products-carousel__slider">
            <div class="block-products-carousel__preloader"></div>

            <ngb-carousel #carousel (touchmove)="handleTouchMove($event)">
                <ng-template ngbSlide *ngFor="let column of columns">
                    <div
                        [ngClass]="{'block-products-carousel__column': column.length > 1, 'single-product-column': column.length === 1}">
                        <div *ngFor="let product of column" class="block-products-carousel__cell">
                            <app-product-card [product]="product"></app-product-card>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>



            <!-- <owl-carousel-o [options]="carouselOptions" appOwlPreventClick #carousel>
                <ng-template *ngFor="let column of columns" carouselSlide>
                    <div class="block-products-carousel__column">
                        <div *ngFor="let product of column" class="block-products-carousel__cell">
                            <app-product-card [product]="product"></app-product-card>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o> -->
        </div>
    </div>
</div>
<!-- .block-products-carousel / end -->