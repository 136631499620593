
export const cFiltros = {
    Marca: 'brand',
    Color: 'color',
    Descuento: 'discount',
    Precio: 'price',
};



