import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StoreService } from 'src/app/shared/services/store.service';
import { DirectionService } from '../../../shared/services/direction.service';
import { PaginasService } from '../../../shared/services/paginas.service';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent {
    @Input() withDepartments = false;

    slides = [];
    options: any;

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService,
        public pagina: PaginasService,
        public StoreSvc: StoreService
    ) {
        this.CargarAcordeones();
        
        // Initialize options after direction is fully initialized
        this.options = {
            nav: false,
            dots: true,
            loop: true,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: false,
            autoplay: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoplaySpeed: 800,
            autoplayTimeout: 5000,
            navSpeed: 700,
            responsive: {
                0: { items: 1 }
            },
            rtl: this.direction.isRTL()
        };
    }

    private async CargarAcordeones() {
        if (this.slides === undefined || this.slides.length === 0) {
            await this.pagina.cargarAcordeon().then((resp: any) => {
                this.slides = resp;
            });
        }
    }
}
