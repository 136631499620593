export const Cpaginas = {
    acercaNosotros: 1,
    informacionEnvio: 2,
    terminosCondiciones: 3,
    politicasPrivacidad: 4,
    blog: 5,
    fag: 6,
    contactenos: 7
};

