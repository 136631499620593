import {Item} from './Items';

export class ArticuloSeleccionado {
    breadcrumbs: Array<{

        label: string;
        url: string;
    }>;
    item: Item;

}
