<div class="block">
    <div class="container">
        <div class="not-found">
            <div class="not-found__404">
                Oops! Error 404
            </div>

            <div class="not-found__content">
                <h1 class="not-found__title">Sitio no encontrado</h1>

                <p class="not-found__text">
                    Parece que no podemos encontrar la página que estás buscando.<br>
                    Intenta utilizar la búsqueda.
                </p>

                <form class="not-found__search" novalidate>
                    <input type="text" class="not-found__search-input form-control" placeholder="Buscar...">
                    <button type="submit" class="not-found__search-button btn btn-primary">Buscar</button>
                </form>

                <p class="not-found__text">
                    O ve a la página de inicio para empezar de nuevo.
                </p>

                <a class="btn btn-secondary btn-sm" href="index.html">Ir al inicio</a>
            </div>
        </div>
    </div>
</div>
