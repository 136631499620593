<div class="site-footer">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <app-footer-contacts></app-footer-contacts>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="Información" [links]="links"></app-footer-links>
                </div>
                <div class="col-6 col-md-3 col-lg-2" *ngIf="this.storeSvc.configuracionSitio.VerSuscribirse">
                    <app-footer-links header="Mi Cuenta" [links]="linksMicuenta"></app-footer-links>
                </div>
                <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div>
            </div>
        </div>

        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                Powered by <a [href]="theme.author.profile_url" target="_blank">{{ theme.author.name }}</a>
            </div>

        </div>
    </div>
    <app-whatsapp-button></app-whatsapp-button>
    <app-totop></app-totop>
</div>
