<!-- .block-categories -->
<div class="block block--highlighted block-categories block-categories--layout--{{ layout }}">
    <div class="container">
        <app-block-header [header]="header"></app-block-header>

        <div class="block-categories__list">
            <div *ngFor="let category of categories" class="block-categories__item category-card category-card--layout--{{ layout }}">
                <div class="category-card__body">
                    <div class="category-card__image">
                        <a [routerLink]="RutaShop +'/' +category.slug"><img [src]="category?.image" alt="" loading="lazy"></a>
                    </div>
                    <div class="category-card__content">
                        <div class="category-card__name">
                            <a [routerLink]="RutaShop +'/' + category.slug">{{ category?.name | titlecase}}</a>
                        </div>
                        <ul class="category-card__links">
                            <li *ngFor="let category of category?.children?.slice(0, 5)">
                                <a [routerLink]="RutaShop +'/' + category.slug">{{ category?.name | titlecase}}</a>
                            </li>
                        </ul>
                        <div class="category-card__all">
                            <a [routerLink]="RutaShop +'/' +category.slug" >Ver</a>
                        </div>
                        <div class="category-card__products">
                            {{ category.items }} Products
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-categories / end -->
