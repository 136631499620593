<div class="footer-newsletter" *ngIf="this.store.configuracionSitio.VerNoticias">
    <h5 class="footer-newsletter__title">Canal de Noticias</h5>
    <div class="footer-newsletter__text">
        Suscríbete a nuestro canal de noticias para estar informado de todos nuestros descuentos y promociones
    </div>

    <form action="" class="footer-newsletter__form" novalidate>
        <label class="sr-only" for="footer-newsletter-address">Correo Electrónico</label>
        <input type="text" class="footer-newsletter__form-input form-control" id="footer-newsletter-address" placeholder="Correo Electrónico...">

        <button class="footer-newsletter__form-button btn btn-primary">Suscríbete</button>
    </form>

    <div class="footer-newsletter__text footer-newsletter__text--social">
        Síguenos en nuestras redes sociales
    </div>

    <app-social-links class="footer-newsletter__social-links" shape="circle"></app-social-links>
</div>
