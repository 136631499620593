<!-- .block-banner -->
<div class="block block-banner">
    <div class="container">
        <a href="" class="block-banner__body">
            <img class="block-banner__image block-banner__image--desktop"[src]='infoBanner?.imagenFull' loading="lazy" alt="banner">
            <img class="block-banner__image block-banner__image--mobile" [src]='infoBanner?.imagenMobile' loading="lazy" alt="banner">
            <div class="block-banner__title"*ngIf="StoreSvc.configuracionSitio.VerBannerInformacion"  >{{infoBanner?.titulo}}</div>
            <div class="block-banner__text" *ngIf="StoreSvc.configuracionSitio.VerBannerInformacion" >{{infoBanner?.descripcion}} </div>
           <!--  <div class="block-banner__button">
                <span class="btn btn-sm btn-primary">Comprar Ahora</span>
            </div> -->
        </a>
    </div>
</div>
<!-- .block-banner / end -->
